<template>
    <el-container>
        <el-main>
            <el-tabs v-model="activeName" @tab-click="handleTabClick">
                <el-tab-pane label="打印设备" name="Device">
                    <Device :list="list" @add-printer="addPrinter" @delete-printer="deletePrinter"></Device>
                </el-tab-pane>
                <el-tab-pane label="打印模板设置" name="Templatesetting">
                    <Templatesetting :printerInfo="printerInfo" @handle-input="handleInput"></Templatesetting>
                </el-tab-pane>
                <el-tab-pane label="打印规则设置" name="Rulesetting">
                    <Rulesetting :printerInfo="printerInfo" @handle-input="handleInput" :list="list"></Rulesetting>
                </el-tab-pane>
            </el-tabs>
            <Preservation @preservation="preservation"></Preservation>
        </el-main>
    </el-container>
</template>

<script>
import Preservation from '@/components/preservation'
import Device from './components/device.vue'
import Templatesetting from './components/tsetting.vue'
import Rulesetting from './components/rulesetting.vue'
export default {
    components: {
        Device,
        Templatesetting,
        Rulesetting,
        Preservation
    },
    data () {
        return {
            activeName: "Device",
            printerInfo: {
                is_print_store_name: 1,
                is_print_member_card: 1,
                is_print_user_name: 1,
                is_print_mobile: 1,
                type: 1,
                store_name: "",
                is_sensitive_member_card: 1,
                is_sensitive_user_name: 1,
                is_sensitive_mobile: 1,
                print_quantity: 1,
                name: "店铺",
                is_meal_code: 0,
                is_wifi_name: 0,
            },
            list: []
        };
    },
    created () {
        this.getPrinterList()
        this.getPrinterInfo()
    },
    methods: {
        getPrinterList () {
            this.$axios.post(this.$api.cashier.PrinterList)
                .then(res => {
                    if (res.code == 0) {
                        this.list = res.result
                        if (this.list.length == 0) {
                            this.list.push({
                                remark: '',
                                sn: '',
                                key: ''
                            })
                        }
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        getPrinterInfo () {
            this.$axios.post(this.$api.cashier.CashierPrinterConfig)
                .then(res => {
                    if (res.code == 0) {
                        if (res.result) {
                            this.printerInfo = res.result
                        }
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        addPrinter () {
            this.list.push({
                remark: '',
                sn: '',
                key: ''
            })
        },
        deletePrinter (index) {
            this.list.splice(index, 1)
        },
        preservation () {
            if (this.list.some(v => !v.remark || !v.sn || !v.key)) {
                return this.$message.warning('请将打印机设备信息填写完整');
            }
            this.list.forEach(async item => {
                if (!item.id) {
                    let res = await this.$axios.post(this.$api.cashier.PrinterAdd, item)
                    if (res.code != 0) {
                        return this.$message.error(res.msg);
                    }
                }
            })
            this.$axios.post(this.$api.cashier.CashierPrinterEdit, this.printerInfo)
                .then(res => {
                    if (res.code == 0) {
                        this.$message.success('保存成功');
                        this.getPrinterInfo()
                        this.getPrinterList()
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        handleInput (obj) {
            this.printerInfo[obj.attr] = obj.val
        }
    },
};
</script>

<style lang="less" scoped>
.el-container {
    height: 100%;
}

.el-main {
    height: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;
    padding: 20px 40px 70px;
}
</style>
